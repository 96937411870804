import { useState, useEffect } from 'react';
import detectButton from 'core/utils/helpers/detectButton';

export const useIsButtonInstalled = () => {
  const [isButtonInstalled, setIsButtonInstalled] = useState(false);

  useEffect(() => {
    detectButton.then((hasButton) => setIsButtonInstalled(hasButton));
  }, []);

  return isButtonInstalled;
};
