import mergeModuleConfigs from 'core/utils/mergeModuleConfigs';
import { footer as orgConfig } from 'org/configs/modules.config';

const defaultConfig = {
  defaults: {
    checkShouldShowBackButtonInMobileFooter: () => (true),
  },
  h: {
    checkShouldShowBackButtonInMobileFooter: () => (false),
  },
};

export default mergeModuleConfigs(defaultConfig, orgConfig);
