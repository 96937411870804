import React from 'react';
import { ShowOnMQ } from 'core/modules/MediaQueries/MediaQueries';

export function SearchSection({ clickHandler, isSearchExpanded }) {
  return (
    <button
      className="mn_navigationItem mn_searchTrigger"
      aria-expanded={isSearchExpanded}
      aria-label="Toggle search"
      onClick={clickHandler}
    >
      <ShowOnMQ mobile>
        <i className="mn_searchTriggerOpen mn_deltaSVGIconNewSearch" aria-hidden="true" />
      </ShowOnMQ>
      <ShowOnMQ tablet desktop>
        <i className="mn_searchTriggerOpen mn_deltaSVGIconSearch" aria-hidden="true" />
      </ShowOnMQ>
    </button>
  );
}

export default SearchSection;
