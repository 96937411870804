import React from 'react';
import { useLocation } from 'react-router-dom';
import Loadable from 'react-loadable';
import { getParamsFromSearch } from 'core/utils/helpers/getParamsFromSearch';

const LoadableWrapper = pageComponentPath => Loadable({
  loader: () => import(`./pages${pageComponentPath}`),
  loading: () => (null),
});

export const routesConfig = [
  { name: 'home', route: '/h____.htm?', PageComponent: LoadableWrapper('/Home') },
  { name: 'index', route: '/index.php', PageComponent: Index },
];

// Route to the correct page if is passed as a query parameter (e.g. /index.php?p=h)
function Index() {
  const location = useLocation();
  const queryParams = getParamsFromSearch(location.search);
  const { p: pageName } = queryParams;

  switch (pageName) {
    case 'h': {
      const Home = LoadableWrapper('/Home');
      return <Home />;
    }
    default: {
      return null;
    }
  }
}
