import React from 'react';
import { useSelector } from 'react-redux';

import { selectUserName, selectUserIsIdentified, selectDisplayableUserId } from 'core/selectors/user';
import { selectSiteUrl } from 'core/selectors/app';

import './SidebarWelcomeMessage.scss';

function MessageForIdentifiedUser({ siteUrl, userName, displayableUserId }) {
  return (
    <div className="mn_mobileNavWelcomeMessage">
      <span className="mn_userInfoWrap">
        <strong>Hello, {userName} SkyMiles #: {displayableUserId}</strong>
        <a className="mn_accountLink" href={`${siteUrl}/ai____.htm`} title="Opens my account page">
          <i className="mn_deltaSVGIconMyAccount" aria-hidden="true" />
        </a>
      </span>
      <p><a className="mn_button mn_logoutLink" href={`${siteUrl}/logoutUser.php?lUrl=/`}>Log out</a></p>
    </div>
  );
}

function SidebarWelcomeMessage() {
  const siteUrl = useSelector(selectSiteUrl);
  const userName = useSelector(selectUserName);
  const displayableUserId = useSelector(selectDisplayableUserId);
  const userIsIdentified = useSelector(selectUserIsIdentified);

  return userIsIdentified &&
    <MessageForIdentifiedUser siteUrl={siteUrl} userName={userName} displayableUserId={displayableUserId} />;
}

export default SidebarWelcomeMessage;
