import React from 'react';
import { ShowOnMQ } from 'core/modules/MediaQueries/MediaQueries';

import './SearchBarTrigger.scss';

function SearchBarTrigger({ ariaExpanded, onClick }) {
  return (
    <div className="mn_searchTriggerWrapper">
      <button
        className="mn_searchTrigger"
        aria-label="Display search field"
        onClick={onClick}
        aria-expanded={ariaExpanded}
      >
        <ShowOnMQ mobile>
          <i className="mn_searchTriggerOpen mn_deltaSVGIconNewSearch" aria-hidden="true" />
        </ShowOnMQ>
        <ShowOnMQ tablet desktop>
          <i className="mn_searchTriggerOpen mn_deltaSVGIconSearch" aria-hidden="true" />
        </ShowOnMQ>
        <i className="mn_searchTriggerClose mn_deltaSVGIconClose" aria-hidden="true" />
      </button>
    </div>
  );
}

export default SearchBarTrigger;
